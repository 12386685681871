<template>
  <CModalExtended :title="getTitle" color="dark" :show.sync="modalActive" size="lg">
    <dataTableExtended
     class="align-center-row-datatable"
      :items="formatedList"
      :loading="loading"
      :fields="fields"
      table-filter
      column-filter
       
    >
      <template #loading>
          <loading/>
      </template>
      <template #Status="{item}">
        <td class="center-cell">
          <CBadge :color="getBadge(item.Status)">
            {{ $t('label.'+item.Status) }}
          </CBadge>
        </td>
      </template>
    </dataTableExtended>
    <template #footer>
      <CButton
        square
        color="wipe"        
        class="d-flex align-items-center"
        @click="toggle(false)"
      >
        <CIcon name="x" /><span class="ml-2"
          >{{$t('button.exit')}}</span
        >
      </CButton>
    </template>
  </CModalExtended>
</template>
<script>

import GeneralMixin from '@/_mixins/general';

function toggle(newVal) {
  this.modalActive = newVal;
}

function getBadge(status) {
  switch (status) {
    case 'ACTIVO':
      return 'success';
    case 'INACTIVO':
      return 'danger';
    default:
      'primary';
  }
}

function data() {
  return {
    modalActive: this.modal,
    list: [],
    loading: true,    
  };
}

function formatedList() {
  return this.list.map((item, index) => {
    return {
      ...item,
      Nro: index + 1,
      FgGeneral: item.FgGeneral ? 'SI' : 'NO',
    };
  });
}

function insertListRolesByModule(val) {
  this.loading = true;
  this.$http
    .get(`Role-by-module?ModuleId=${val}&auth=true`)
    .then((response) => {
      this.list = response.data.data;
      this.loading = false;
    })
    .catch((e) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function getTitle() {
  return this.$t('label.seeRolesAssociatedWithTheModule') + ': ' + this.moduleNbSelect;
}

function fields(){

 return[
      { label: '#', filter: false, key: 'Nro' },
      { label: this.$t('label.name'), key: 'RoleName', _classes: 'text-uppercase text-center' },
      {
        label:this.$t('label.dashLogin'),
        key: 'TransaLogin',
        _classes: 'text-uppercase text-center',
      },
      { key: 'FgGeneral', label: this.$t('label.general'), _classes: 'text-uppercase text-center' },
      { label: 'STATUS', key: 'Status', _classes: 'text-uppercase center-cell',_style:'width:15%' },
    ]
}
export default {
  name: 'list-roles-module-modal',
  props: {
    modal: Boolean,
    idModule: String,
    moduleNbSelect: String,
  },
  data,
  mixins: [GeneralMixin],
  methods: {
    toggle,
    insertListRolesByModule,
    getBadge,
  },
  computed: { formatedList, getTitle,fields }, 
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    idModule: function(val) {
      this.insertListRolesByModule(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    },
  },
};
</script>
<style lang="scss">
.img-index-imdg {
  width: 50px;
  height: 50px;
}
.center-cell {
  text-align: center;
}
 
</style>